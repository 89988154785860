<template>
  <router-link v-if="brandCollection" :to="{name: 'Collection', params: {id: brandCollection.id}}">
    {{ brandCollection.name }}
  </router-link>
  <span v-else>{{ $t('common.misc.NA') }}</span>
</template>

<script>
export default {
  name: "BrandCollectionLink",

  props: {
    brandCollection: {type: Object, default: () => null}
  }
}
</script>

<style scoped>

</style>
